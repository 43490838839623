<template>
  <st-title :title="$t('studio.lnb.common_2depth_tutorial_video')" />
  <div class="grid grid-cols-3 gap-x-24 gap-y-32 mt-40">
    <tutorial-item v-for="(tutorial, k) in tutorialList" :key="k" :tutorial="tutorial" />
  </div>
</template>
<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import { useLocalizedImage } from '@/assets/images/tutorial';
import StTitle from '@/components/common/st-title.vue';
import TutorialItem from '@/components/tutorial-video/tutorial-item.vue';
import { INTRO_VIDEO_URLS } from '@/constants/videos.const';
import { LanguageCode } from '@/enums/language-code.enum';
import type { Tutorial } from '@/types/tutorial';
import { definePageMeta } from '#imports';

definePageMeta({
  middleware: [
    'check-login-middleware'
  ]
});

const { t, locale } = useI18n();

const ImageVideo1 = useLocalizedImage('ImageVideo1');
const ImageVideo2 = useLocalizedImage('ImageVideo2');
const ImageVideo3 = useLocalizedImage('ImageVideo3');
const ImageVideo4 = useLocalizedImage('ImageVideo4');
const ImageVideo5 = useLocalizedImage('ImageVideo5');
const ImageVideo6 = useLocalizedImage('ImageVideo6');
const ImageVideo7 = useLocalizedImage('ImageVideo7');
const ImageVideo8 = useLocalizedImage('ImageVideo8');
const ImageVideo9 = useLocalizedImage('ImageVideo9');

const videoUrl = computed(() => {
  return INTRO_VIDEO_URLS[locale.value] || INTRO_VIDEO_URLS[LanguageCode.Ko];
});

const tutorialList: Tutorial[] = [
  {
    image: ImageVideo1,
    imageAlt: 'All Improved Studio Features',
    title: t('studio.brand_page.feature1_main_title'),
    description: t('studio.brand_page.feature1_sub_title'),
    srcVideo: videoUrl.value,
    isActive: true
  },
  {
    image: ImageVideo2,
    imageAlt: 'Project management',
    title: t('studio.brand_page.feature2_main_title'),
    description: t('studio.brand_page.feature2_sub_title'),
    srcVideo: videoUrl.value,
    isActive: false
  },
  {
    image: ImageVideo3,
    imageAlt: 'Product Home and Product Settings',
    title: t('studio.brand_page.feature3_main_title'),
    description: t('studio.brand_page.feature3_sub_title'),
    srcVideo: videoUrl.value,
    isActive: false
  },
  {
    image: ImageVideo4,
    imageAlt: 'Product Page',
    title: t('studio.brand_page.feature4_main_title'),
    description: t('studio.brand_page.feature4_sub_title'),
    srcVideo: videoUrl.value,
    isActive: false
  },
  {
    image: ImageVideo5,
    imageAlt: 'Upload Build',
    title: t('studio.brand_page.feature5_main_title'),
    description: t('studio.brand_page.feature5_sub_title'),
    srcVideo: videoUrl.value,
    isActive: false
  },
  {
    image: ImageVideo6,
    imageAlt: 'Register Business',
    title: t('studio.brand_page.feature6_main_title'),
    description: t('studio.brand_page.feature6_sub_title'),
    srcVideo: videoUrl.value,
    isActive: false
  },
  {
    image: ImageVideo7,
    imageAlt: 'Price and Policies',
    title: t('studio.brand_page.feature7_main_title'),
    description: t('studio.brand_page.feature7_sub_title'),
    srcVideo: videoUrl.value,
    isActive: false
  },
  {
    image: ImageVideo8,
    imageAlt: 'Official Release',
    title: t('studio.brand_page.feature8_main_title'),
    description: t('studio.brand_page.feature8_sub_title'),
    srcVideo: videoUrl.value,
    isActive: false
  },
  {
    image: ImageVideo9,
    imageAlt: 'Discounts',
    title: t('studio.brand_page.feature9_main_title'),
    description: t('studio.brand_page.feature9_sub_title'),
    srcVideo: videoUrl.value,
    isActive: false
  }
];
</script>
